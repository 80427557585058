type MessageHandler = {
  postMessage(msg: unknown): void
}

type WKWebView = {
  messageHandlers: { [key: string]: MessageHandler }
}

declare global {
  interface Window {
    webkit: WKWebView
  }
}

export function postMessage(handler: string, args: unknown = {}): boolean {
  if (!window.webkit || !window.webkit.messageHandlers || !window.webkit.messageHandlers[handler]) {
    return false
  }

  window.webkit.messageHandlers[handler].postMessage(args)
  return true
}

export function alert(message: string) {
  return postMessage('alertMessage', message)
}
