import { onConnect } from '@sonicgarden/onconnect'
import { default as Swiper } from 'swiper'
import { Autoplay, FreeMode, Navigation } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

onConnect('.js-promotion-banner-swiper', (el) => {
  const count = el.querySelectorAll('.swiper-slide').length
  const modules = [Navigation]
  if (count > 1) {
    modules.push(Autoplay)
  }

  new Swiper(el, {
    modules,
    slidesPerView: 'auto',
    spaceBetween: 20,
    centeredSlides: true,
    autoplay: {
      delay: 5000,
    },
    loop: true,
    navigation: {
      prevEl: '.swiper-button-prev',
      nextEl: '.swiper-button-next',
    },
  })
})

onConnect('.js-pickup-books-swiper', (el) => {
  new Swiper(el, {
    modules: [FreeMode, Autoplay],
    centeredSlides: true,
    slidesPerView: 'auto',
    initialSlide: 3,
    freeMode: {
      enabled: true,
    },
    autoplay: {
      delay: 2000,
    },
    loop: true,
  })
})

onConnect('.js-kids-banner-swiper', (el) => {
  const count = el.querySelectorAll('.swiper-slide').length
  const modules = count > 1 ? [Autoplay] : []
  new Swiper(el, {
    modules,
    autoplay: {
      delay: 5000,
    },
    loop: true,
  })
})

onConnect('.js-app-guide-swiper', (el) => {
  const initialSlide = Number(el.dataset.initialSlide) || 0
  new Swiper(el, {
    slidesPerView: 3.5,
    spaceBetween: 15,
    initialSlide,
  })
})
