import { onConnect } from '@sonicgarden/onconnect'
import { axios, initializedHeaders } from 'lib/axios'

function fetch(url) {
  return axios({
    type: 'GET',
    url,
    headers: initializedHeaders(),
  })
}

function render(outerElement, response) {
  outerElement.innerHTML = response.data
}

const observer = new IntersectionObserver((entries) => {
  for (const entry of entries) {
    if (!entry.isIntersecting) return

    const { target } = entry
    observer.unobserve(target) // NOTE: 二重リクエスト防止
    fetch(target.dataset.url).then((res) => render(target, res))
  }
})

onConnect('.js-lazy-load', (el) => {
  observer.observe(el)
})
