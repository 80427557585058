import { onConnect } from '@sonicgarden/onconnect'
import * as android from 'lib/bridge_android'
import * as ios from 'lib/bridge_ios'
import { isAndroidApp } from 'lib/platform'

class AndroidBridge {
  sendText(text) {
    return android.call('sendText', text)
  }

  alertUnsupported() {
    android.alert(
      'サポートしていないバージョンです。Google Play Storeからアプリをアップデートしてもう一度お試しください。',
    )
  }
}

class IOSBridge {
  sendText(text) {
    return ios.postMessage('sendText', text)
  }

  alertUnsupported() {
    ios.alert('サポートしていないバージョンです。App Storeからアプリをアップデートしてもう一度お試しください。')
  }
}

function sendText(text) {
  const bridge = isAndroidApp() ? new AndroidBridge() : new IOSBridge()
  if (!bridge.sendText(text)) {
    bridge.alertUnsupported()
  }
}

function handleClick(ev) {
  ev.preventDefault()
  const el = ev.currentTarget
  const text = el.dataset.text
  if (text) {
    sendText(text)
  }
}

onConnect('.js-send-text', (el) => {
  el.addEventListener('click', handleClick)
})
