function hasHistory(): boolean {
  return window.history.length > 1
}

function fallbackPath(el: HTMLElement): string {
  return el.dataset.backLinkFallbackPath || '/'
}

export function handleClick(event: MouseEvent) {
  const el = event.currentTarget as HTMLElement | null
  if (!el) return

  event.preventDefault()
  if (hasHistory()) {
    window.history.back()
  } else {
    location.href = fallbackPath(el)
  }
}
