import * as android from 'lib/bridge_android'
import * as ios from 'lib/bridge_ios'
import { isAndroidApp } from './platform'

type Orientation = 'portrait' | 'landscape' | 'default'

export const rotateScreen = (orientation: Orientation) => {
  if (isAndroidApp()) {
    android.call('rotateScreen', orientation)
  } else {
    ios.postMessage('rotateScreen', { orientation })
  }
}
