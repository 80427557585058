import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel'

$(() => {
  if ($('.js-ehon-video-player-tag').length > 0) {
    setVideoPlayerSize()
  }
  setCarouselForEhonVideos()
})

function setVideoPlayerSize() {
  $('.js-ehon-video-player-tag').attr('width', window.innerWidth)
  $('.js-ehon-video-player-tag').attr('height', window.innerHeight)
}

const setCarouselForEhonVideos = () => {
  $('.js-ehon-videos-content-items').each((_, element) => {
    const $element = $(element)
    $element.owlCarousel({
      loop: true,
      autoWidth: false,
      dots: false,
      margin: 10,
      stagePadding: 50,
      items: 3,
    })
  })
}
