import { onConnect } from '@sonicgarden/onconnect'
import $ from 'jquery'
import * as android from './bridge_android'
import * as ios from './bridge_ios'
import { isAndroidApp } from './platform'

export const logEvent = (name: string, parameters: object = {}) => {
  if (typeof gtag === 'function') {
    gtag('event', name, parameters)
  }
  if (isAndroidApp()) {
    android.call('logEventForFA', name, JSON.stringify(parameters))
  } else {
    ios.postMessage('logEventForFA', { name, parameters })
  }
}

export const setUserProperty = (name: string, value: string) => {
  if (typeof gtag === 'function') {
    gtag('set', 'user_properties', { [name]: value })
  }
  if (isAndroidApp()) {
    android.call('setUserPropertyForFA', name, value)
  } else {
    ios.postMessage('setUserPropertyForFA', { name, value })
  }
}

export const triggerPageViewEvent = (pageTitle?: string) => {
  logEvent('page_view', {
    page_title: pageTitle || document.title,
    page_location: location.href,
  })
}

const installInAppClickTracker = () => {
  $(document).on('click', 'a', (event) => {
    const el = event.currentTarget
    logEvent('in_app_click', {
      location: location.href,
      link_url: el.href,
      link_id: el.id,
      link_classes: el.className,
      link_text: (el.textContent || '').slice(0, 100),
      page_title: document.title,
    })
  })
}

const installShopLinkClickTracker = () => {
  onConnect('a.js-shop-link', (el) => {
    el.addEventListener('click', () => {
      const isbn = el.dataset.contentIsbn

      logEvent('shop_link_click', {
        location: location.href,
        link_url: (el as HTMLLinkElement).href,
        link_isbn: isbn,
        link_text: (el.textContent || '').slice(0, 100),
        page_title: document.title,
      })
    })
  })
}

const installPageViewTracker = () => {
  $(() => {
    triggerPageViewEvent()
  })
}

const installOnLoadEventTrancker = () => {
  $(() => {
    for (const el of document.querySelectorAll<HTMLElement>('.js-ga-on-load-event')) {
      const { gaEventName, gaEventParams } = el.dataset
      if (!gaEventName) {
        return
      }

      const params = gaEventParams ? JSON.parse(gaEventParams) : {}
      logEvent(gaEventName, params)
    }
  })
}

const installUserProfileSetter = () => {
  $(() => {
    const { user } = window.APP.rails
    // NOTE: 99 はビジター
    const memberType = user ? user.premium_member_type : '99'
    setUserProperty('premium_member_type', memberType)
  })
}

export const start = () => {
  installInAppClickTracker()
  installPageViewTracker()
  installOnLoadEventTrancker()
  installUserProfileSetter()
  installShopLinkClickTracker()
}
