import { showSpinner, hideSpinner } from 'application/spinner'
import $ from 'jquery'
import { axios, initializedHeaders } from 'lib/axios'
import { isAndroidApp } from 'lib/platform'
import * as android from './android'
import * as ios from './ios'

const availableInAppPurchase = () => {
  return document.body.dataset.availableInAppPurchase === 'true'
}

const fetchCurrentPremiumMemberStatus = async () => {
  try {
    const { data } = await axios({
      url: '/premium/member_status.json',
      method: 'GET',
      headers: initializedHeaders(),
    })
    return data.premium_member_type
  } catch {
    return null
  }
}

const platform = isAndroidApp() ? android : ios

window.MOBILE_APP.hideSpinner = () => {
  hideSpinner()
}

platform.init()
$(() => {
  platform.onLoad()
})

$(document).on('click', '.js-premium-register', async (e) => {
  showSpinner()
  const { product, productName, supportedProduct, offer, force } = e.currentTarget.dataset
  if (availableInAppPurchase()) {
    if (supportedProduct === 'true') {
      const status = await fetchCurrentPremiumMemberStatus()
      if (status === '0') {
        platform.register(product, { offerId: offer, force })
      } else {
        if (status === '1') {
          platform.alertMessage(
            'すでにプレミアム会員です。プレミアムの機能が使えない場合は、一度サインアウトしていただき、サインインし直してみてください。',
          )
        } else {
          platform.alertMessage('認証に失敗しました。サインイン状況をご確認ください。')
        }
        setTimeout(hideSpinner)
      }
    } else {
      platform.unsupportedProduct(productName)
      setTimeout(hideSpinner)
    }
  } else {
    platform.unavailable('register')
    setTimeout(hideSpinner)
  }
})

$(document).on('click', '.js-premium-restore', (e) => {
  showSpinner()
  if (availableInAppPurchase()) {
    platform.restore()
  } else {
    platform.unavailable('restore')
    setTimeout(hideSpinner)
  }
})
