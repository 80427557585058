import { onConnect } from '@sonicgarden/onconnect'
import $ from 'jquery'
import { axios, initializedHeaders } from '../lib/axios'

function toggleSpinner(el, { show }) {
  const spinner = el.querySelector('.js-spinner')
  if (show) {
    spinner.classList.remove('d-none')
  } else {
    spinner.classList.add('d-none')
  }
}

function showAlreadyReceived(missionType) {
  const el = document.querySelector(`.js-already-received[data-mission-type=${missionType}]`)
  if (el) {
    el.classList.remove('d-none')
  }
}

async function handleClick(ev) {
  const el = ev.currentTarget
  if (el.classList.contains('disabled')) {
    return
  }

  const { missionType, completeDelayMs } = el.dataset
  toggleSpinner(el, { show: true })
  el.setAttribute('disabled', 'disabled')
  try {
    const url = el.dataset.receiveDpointMissionUrl
    await axios({
      url,
      method: 'PATCH',
      headers: initializedHeaders(),
    })

    setTimeout(
      () => {
        $(`.js-dpoint-mission-completed[data-mission-type=${missionType}]`).modal('show')
        showAlreadyReceived(missionType)
      },
      Number(completeDelayMs || 0),
    )
  } catch {
    el.removeAttribute('disabled')
  } finally {
    toggleSpinner(el, { show: false })
  }
}

onConnect('.js-receive-dpoint-button', (el) => {
  el.addEventListener('click', handleClick)
})

onConnect('.js-dpoint-mission-start-audio', (el) => {
  el.addEventListener('click', () => {
    const audio = document.querySelector('.js-dpoint-mission-audio')
    audio.play()
    audio.classList.remove('d-none')
  })
})
