export const isAndroidApp = () => {
  return !!(window as any).Android
}

export const isIOSApp = () => {
  const global: any = window
  return global.webkit && global.webkit.messageHandlers
}

export const isApp = () => {
  return isAndroidApp() || isIOSApp()
}
