import { onConnect } from '@sonicgarden/onconnect'

function render(source, dest) {
  const html = source.querySelector('template').innerHTML
  dest.innerHTML = html
}

onConnect('.js-update-element', (el) => {
  const dest = document.getElementById(el.dataset.targetId)
  if (!dest) {
    return
  }

  render(el, dest)
})
