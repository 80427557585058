import { onConnect } from '@sonicgarden/onconnect'
import { axios, initializedHeaders } from 'lib/axios'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
import { trackEvent } from 'lib/track'

declare const jstream_t3: {
  PlayerFactoryOBJ: {
    create: (setting: JStreamPlayerSetting, id: string) => JStreamPlayer
  }
}

// https://support.eq.stream.co.jp/hc/ja/articles/115008545028-EQPlayerAccessAPI%E3%82%AF%E3%83%A9%E3%82%B9
type JStreamAccessorEventName =
  'landing' |
  'change_state' |
  'buffering' |
  'playing' |
  'progress' |
  'paused' |
  'seek_start' |
  'complete' |
  'fullscreen' |
  'exitFullscreen'

type JStreamPlayer = {
  accessor: {
    play: () => void
    state?: string
    addEventListener: (event: JStreamAccessorEventName, listener: unknown) => void
    removeEventListener: (event: JStreamAccessorEventName, listener: unknown) => void
  }
}

type JStreamPlayerSetting = {
  b: string
  c: string
  m?: string
  pl?: string
  s: {
    wp: number
    hp: number
    rb: 'on' | 'off'
    plac: 'on' | 'off'
    dq: '0' | '1' | '2' | '3' | '4' | '5'
    mdq: '0' | '1' | '2' | '3' | '4' | '5'
    tg: 'on' | 'off'
    il: 'on' | 'off'
    rp: 'on' | 'off'
    sn: string
  }
}

const trackPlayOn = (el: HTMLElement) => {
  const { movieCode, playlistCode } = el.dataset

  if (movieCode) {
    trackEvent('click_movie_play', { movie_code: movieCode })
  } else if (playlistCode) {
    trackEvent('click_playlist_play', { playlist_code: playlistCode })
  }
}

const createMoviePlayHistory = (el: HTMLElement) => {
  axios({
    url: el.dataset.playHistoryUrl,
    method: 'POST',
    headers: initializedHeaders(),
  })
}

const createPlayer = (el: HTMLElement | null): JStreamPlayer | undefined => {
  if (!el) return

  const { movieCode, playlistCode } = el.dataset
  if ((movieCode && playlistCode) || (!movieCode && !playlistCode)) {
    throw 'Only one of movieCode or playlistCode should be specified.'
  }

  // https://support.eq.stream.co.jp/hc/ja/articles/115008543548
  const setting: JStreamPlayerSetting = {
    b: 'eqa553driy.eq.webcdn.stream.ne.jp/www50/eqa553driy/jmc_pub/jmc_swf/player/',
    c: 'OTAx',
    m: movieCode,
    pl: playlistCode,
    s: {
      wp: 1920, // max-width, max-height として扱われるだけなので、大きめの端末を考慮してある程度のサイズで設定しておく
      hp: 1080,
      rp: 'on', // レスポンシブ対応の有無
      plac: playlistCode ? 'on' : 'off', // プレイリストの連続再生の有無
      dq: '0', // 再生する動画のデフォルト画質(0: 自動)
      mdq: '0', // 再生する動画のデフォルト画質(スマートフォン向け, 0: 自動)
      tg: 'off', // タグ表示の有無
      il: 'off', // インバウンドリンクの有無
      rb: 'off', // 「もう一度見る」ボタンの表示有無
      sn: '', // 表示するSNSボタンのリスト
    }
  }

  // TODO: OBJ 版は非推奨となっているので IF 版に切り替えたい
  return jstream_t3.PlayerFactoryOBJ.create(setting, el.id)
}

function preparePlayerElement(playerElement: HTMLElement): ReturnType<typeof createPlayer> {
  const player = createPlayer(playerElement)
  if (!player) return

  const listener = () => {
    createMoviePlayHistory(playerElement)
    trackPlayOn(playerElement)
    player.accessor.removeEventListener('playing', listener)
  }
  player.accessor.addEventListener('playing', listener)
  return player
}

onConnect('.js-movie-player-base', (playerElement) => {
  const player = preparePlayerElement(playerElement)
  if (!player) { return }

  onConnect('.js-movie-play-button', (playButtonElement) => {
    playButtonElement.addEventListener('click', () => {
      player.accessor.play()
    })
  })
})

onConnect('.js-create-movie-player-button', (buttonElement) => {
  buttonElement.addEventListener('click', () => {
    const { playerId } = buttonElement.dataset
    if (!playerId) { return }

    const playerElement = document.getElementById(playerId)
    if (!playerElement) { return }

    const player = preparePlayerElement(playerElement)
    if (!player) { return }

    // NOTE: 再生できる状態になったら再生を開始する
    if (player.accessor.state === 'landing') {
      player.accessor.play()
    } else {
      player.accessor.addEventListener('landing', () => {
        player.accessor.play()
      })
    }
  })
})
