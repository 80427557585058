import { hideSpinner } from 'application/spinner'
import { axios, initializedHeaders } from 'lib/axios'
import * as android from 'lib/bridge_android'
import { logEvent } from 'lib/ga'
import { setUserProfile } from 'lib/track'

const UNAVAILALBE_MESSAGES = {
  register: '購入処理に失敗しました。Google Play Storeからアプリをアップデートしてもう一度お試しください。',
  restore: '復元処理に失敗しました。Google Play Storeからアプリをアップデートしてもう一度お試しください。',
}

let deferredProduct = null
export function register(product, { force }) {
  if (!force && android.call('queryPurchasesAndCallbackTo', 'handlePurchasesForPreCheck')) {
    deferredProduct = product
  } else {
    const p = product == 'deferred' ? deferredProduct : product
    purchaseProduct(p)
    deferredProduct = null
    $('#js-in-app-purchase-alert-modal').modal('hide')
  }
}

function purchaseProduct(product) {
  // NOTE: 念のため不意に deferredProduct が揮発してしまったケースへの対処を入れておく
  if (!product) {
    alertMessage('購入処理に失敗しました。お手数ですが、もう一度はじめから操作しなおしてください。')
    hideSpinner()
    return
  }

  if (!android.call('purchaseProduct', product)) {
    android.call('purchasePremiumServiceProduct')
  }
}

export function restore() {
  hideSpinner()
  // NOTE: Play ストアの定期購入に飛ばす
  location.href = 'https://play.google.com/store/account/subscriptions'
}

export function unsupportedProduct(productName) {
  const msg = `${productName}をサポートしていないバージョンです。Google Play Storeからアプリを最新化してもう一度お試しください。`
  alertMessage(msg)
}

export function unavailable(functionName) {
  const msg = UNAVAILALBE_MESSAGES[functionName]
  alertMessage(msg)
}

export function init() {
  window.MOBILE_APP.receive_google_play_purchase_token = obsoletedReceiveGooglePlayPurchaseToken
  window.MOBILE_APP.receive_google_play_purchase_token_with_product_id = receiveGooglePlayPurchaseToken
  window.MOBILE_APP.handlePurchasesForPreCheck = handlePurchasesForPreCheck
}

export function onLoad() {
  const { user } = window.APP.rails
  if (user) {
    android.call('queryPurchases')
  }
}

function obsoletedReceiveGooglePlayPurchaseToken(_purchaseToken) {
  alertMessage('購入結果の反映に失敗しました。Google Play Storeからアプリをアップデートしてください。')
  hideSpinner()
}

async function receiveGooglePlayPurchaseToken(purchaseToken, productId) {
  // TODO: ここでログイン済みかどうかチェックする https://app.bugsnag.com/sonicgarden/ehon-cp/errors/648981549d79330008adb34b?filters[event.since]=30d&filters[error.status]=open
  try {
    const res = await axios({
      url: '/premium/google_play_purchases.json',
      method: 'POST',
      headers: initializedHeaders(),
      data: {
        purchase_token: purchaseToken,
        subscription_key: productId,
      },
    })
    if (res.status === 204 /* No Content */) {
      return
    }

    const ehonnaviResponseData = res.data
    const returnCode = ehonnaviResponseData['return_code']
    if (![0, 302].includes(returnCode)) {
      throw false
    }

    setUserProfile({ android_purchase_token: purchaseToken })

    logEvent('ehon_in_app_purchase')
    if (returnCode === 0) {
      // NOTE: AndroidのWebViewからだとlocation.reload();が動かない
      location = location.pathname
    } else {
      location.href = ehonnaviResponseData['location']
    }
  } catch {
    alertMessage('定期購入処理中にエラーが発生しました。サポートにお問い合わせください。')
    hideSpinner()
  }
}

function handlePurchasesForPreCheck(productIds, _responseCode) {
  if (productIds.length === 0) {
    purchaseProduct(deferredProduct)
    deferredProduct = null
  } else {
    hideSpinner()
    $('#js-in-app-purchase-alert-modal').modal('show')
  }
}

export function alertMessage(message) {
  android.alert(message)
}
