import { onConnect } from '@sonicgarden/onconnect'
import { axios, initializedHeaders } from 'lib/axios'

const startObserve = (observer: IntersectionObserver, container: HTMLElement) => {
  const target = container.querySelector('.js-contents-carousel-load-more')
  if (target) {
    observer.observe(target)
  }
}

onConnect('.js-contents-carousel', (el) => {
  const observer = new IntersectionObserver(async (entries) => {
    const [entry] = entries

    if (entry.isIntersecting) {
      const target = entry.target as HTMLElement
      observer.unobserve(target)

      const parent = target.parentElement
      const nextUrl = target.dataset.nextUrl
      if (!nextUrl || !parent) {
        return
      }

      const response = await axios({
        method: 'GET',
        url: nextUrl,
        headers: {
          ...initializedHeaders(),
          'X-Requested-With': 'XMLHttpRequest',
        },
      })
      parent.outerHTML = response.data
      startObserve(observer, el)
    }
  })
  startObserve(observer, el)
})
