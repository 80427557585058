import $ from 'jquery'
import { axios, initializedHeaders } from '../lib/axios'

$(document).on('click', '.js-movie-play-history', (e) => {
  const $target = $(e.currentTarget)
  axios({
    url: $target.data('play-history-url'),
    method: 'POST',
    headers: initializedHeaders(),
  })
    .then(() => {
      if ($target.data('is-app')) {
        window.location.href = $target.find('.js-movie-player-app-link').prop('href')
      }
    })
    .catch(() => {
      alert(
        'エラーが発生したためビューアを開くことができませんでした。しばらく時間をおいてから、もう一度お試しください。',
      )
    })
  return false
})
