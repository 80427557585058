export {}

const openDkids = (returnUrl: string) => {
  const messageData = ''
  const form = document.createElement('form')

  document.body.append(form)
  const input = document.createElement('input')
  input.setAttribute('type', 'hidden')
  input.setAttribute('name', 'messageData')
  input.setAttribute('value', messageData)
  form.append(input)

  form.setAttribute('target', '_self')
  form.setAttribute('action', returnUrl)
  form.setAttribute('method', 'post')
  form.submit()
}

window.openDkids = openDkids
