type Android = any
type Argument = string | null

declare global {
  interface Window {
    Android: Android
  }
}

export function call(methodName: string, ...args: Argument[]): boolean {
  if (!window.Android || typeof window.Android[methodName] !== 'function') {
    return false
  }

  window.Android[methodName].apply(window.Android, args)
  return true
}

export function alert(message: string) {
  return call('showPopupMessage', message)
}
