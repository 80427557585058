import $ from 'jquery'

function sample(array) {
  return array[Math.floor(Math.random() * array.length)]
}

const CHALLENGE_OPERANDS = [2, 3, 4, 5, 6, 7, 8, 9]

function setupChallenge() {
  const val1 = sample(CHALLENGE_OPERANDS)
  const val2 = sample(CHALLENGE_OPERANDS)
  $('.js-parental-gate-arg1').text(val1)
  $('.js-parental-gate-arg2').text(val2)
  $('.js-parental-gate-answer').val('')
}

function isChallengeSucceeded() {
  const val1 = Number($('.js-parental-gate-arg1').text())
  const val2 = Number($('.js-parental-gate-arg2').text())
  const userAnswer = $('.js-parental-gate-answer').val()
  return val1 * val2 == userAnswer
}

function focusAnswer() {
  $('.js-parental-gate-answer')[0].focus()
}

function showResult(succeeded) {
  if (succeeded) {
    $('.js-parental-gate-ok').show()
    $('.js-parental-gate-ng').hide()
  } else {
    $('.js-parental-gate-ok').hide()
    $('.js-parental-gate-ng').show()
  }
}

function initParentalGateModal() {
  $('#js-parental-gate-modal').on('show.bs.modal', () => {
    setupChallenge()
    $('.js-parental-gate-ok').hide()
    $('.js-parental-gate-ng').hide()
  })
  $('#js-parental-gate-modal').on('shown.bs.modal', () => {
    focusAnswer()
  })
}

function initParentalGate() {
  $('.js-parental-gate').on('click', (ev) => {
    if (isChallengeSucceeded()) {
      showResult(true)
    } else {
      ev.preventDefault()
      showResult(false)
      focusAnswer()
    }
  })
}

$(() => {
  initParentalGateModal()
  initParentalGate()
})
