import $ from 'jquery'
import * as android from 'lib/bridge_android'
import * as ios from 'lib/bridge_ios'
import { isIOSApp, isAndroidApp } from '../lib/platform'

const isTrackingEnabled = () => {
  return !!window.APP.trackingEnabled
}

type Adapter = {
  trackEvent(name: string, params?: object): void
  setUserId(userId: string): void
  setUserProfile(profile: object): void
}

class IOSAdapter {
  trackEvent(name: string, params?: object) {
    ios.postMessage('trackEvent', { name, params })
  }

  setUserId(userId: string) {
    ios.postMessage('setUserId', userId)
  }

  setUserProfile(profile: object) {
    ios.postMessage('setUserProfile', profile)
  }
}

class AndroidAdapter {
  trackEvent(name: string, params?: object) {
    const eventParams = params ? JSON.stringify(params) : null
    android.call('trackEvent', name, eventParams)
  }

  setUserId(userId: string) {
    android.call('setUserId', userId)
  }

  setUserProfile(profile: object) {
    android.call('setUserProfile', JSON.stringify(profile))
  }
}

class DummyAdapter {
  trackEvent(name: string, params?: object) {
    console.log('event', name, params)
  }

  setUserId(userId: string) {
    console.log('userId', userId)
  }

  setUserProfile(profile: object) {
    console.log('profile', profile)
  }
}

const adapter = (): Adapter => {
  if (isIOSApp()) {
    return new IOSAdapter()
  } else if (isAndroidApp()) {
    return new AndroidAdapter()
  } else {
    return new DummyAdapter()
  }
}

export const trackEvent = (name: string, params?: object) => {
  if (isTrackingEnabled()) {
    adapter().trackEvent(name, params)
  }
}

const extractTrackEvent = (el: HTMLElement) => {
  // trackParams が動的に変わる場合があるので、常に最新の値をとるために dataset を使っている
  const { trackName, trackParams } = el.dataset
  return [trackName, JSON.parse(trackParams || '{}')]
}

const trackEventByElement = (el: HTMLElement) => {
  const [name, params] = extractTrackEvent(el)
  trackEvent(name, params)
}

const setUserId = () => {
  const { user } = window.APP.rails
  if (user && user.ehonnavi_member_no) {
    adapter().setUserId(user.ehonnavi_member_no)
  }
}

export const setUserProfile = (profile: object) => {
  adapter().setUserProfile(profile)
}

const setDefaultUserProfile = () => {
  const { user } = window.APP.rails
  const profile: any = {}
  if (user) {
    if (user.authenticated_by_sugotoku) {
      profile['premium_member_type'] = 2 // NOTE: スゴ得プラン
    } else {
      profile['premium_member_type'] = Number(user.premium_member_type)
    }
    profile['rest_reading_count_this_month'] = user.rest_reading_count_this_month
    // NOTE: `kidsview` 以降の項目についてはすべて文字列として設定を行う
    profile['kidsview'] = user.kidsview ? '1' : '0'
  } else {
    profile['premium_member_type'] = 99 // NOTE: visitor
    profile['rest_reading_count_this_month'] = 0
    // NOTE: `kidsview` 以降の項目についてはすべて文字列として設定を行う
    profile['kidsview'] = '0'
  }
  setUserProfile(profile)
}

if (isTrackingEnabled()) {
  $(document).on('click', '[data-track-action="click"]', (e) => {
    trackEventByElement(e.currentTarget)
  })

  $(() => {
    $('[data-track-action="load"]').each((_, el) => {
      trackEventByElement(el)
    })

    setUserId()
    setDefaultUserProfile()
  })
}
