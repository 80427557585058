import { showSpinner, hideSpinner } from 'application/spinner'
import $ from 'jquery'
import { axios, initializedHeaders } from 'lib/axios'
import * as ios from 'lib/bridge_ios'
import { logEvent } from 'lib/ga'
import { setUserProfile } from 'lib/track'

const UNAVAILALBE_MESSAGES = {
  register: '購入処理に失敗しました。App Storeからアプリをアップデートしてもう一度お試しください。',
  restore: '復元処理に失敗しました。App Storeからアプリをアップデートしてもう一度お試しください。',
}

let _restore = false
export async function register(product, { offerId }) {
  _restore = false
  if (offerId) {
    const res = await axios({
      url: '/premium/promotion_offers.json',
      method: 'POST',
      headers: initializedHeaders(),
      data: {
        product_id: product,
        offer_id: offerId,
      },
    })

    const offer = res.data
    ios.postMessage('purchasePremiumServiceProduct', { product, offer })
  } else {
    ios.postMessage('purchasePremiumServiceProduct', { product })
  }
}

export function restore() {
  _restore = true
  ios.postMessage('restorePremiumSubscription')
}

export function unsupportedProduct(productName) {
  const msg = `${productName}をサポートしていないバージョンです。App Storeからアプリを最新化してもう一度お試しください。`
  alertMessage(msg)
}

export function unavailable(functionName) {
  const msg = UNAVAILALBE_MESSAGES[functionName]
  alertMessage(msg)
}

export function init() {
  window.MOBILE_APP.receive_in_app_purchase_receipts = receiveInAppPurchaseReceipts
  window.MOBILE_APP.send_transaction_key = sendTransactionKey
  window.MOBILE_APP.handle_promotion_offer_check_result = handlePromotionOfferCheckResult
}

export function onLoad() {
  const { user } = window.APP.rails
  if (user) {
    ios.postMessage('sendStoredTransactionKeys')

    const $offer = $('.js-check-promotion-offer-eligible')
    if ($offer.length > 0) {
      checkPromotionOfferEligible($offer[0])
    }
  }
}

async function receiveInAppPurchaseReceipts(receiptString) {
  try {
    const res = await axios({
      url: '/premium/in_app_purchase_receipts.json',
      method: 'POST',
      headers: initializedHeaders(),
      data: {
        receipt_data: receiptString,
        restore: _restore ? 1 : 0,
      },
    })
    const ehonnaviResponseData = res.data
    const originalTransactionKeys = ehonnaviResponseData['original_transaction_keys']
    if (originalTransactionKeys) {
      setUserProfile({ ios_original_transaction_keys: originalTransactionKeys.join(',') })
    }

    const returnCode = ehonnaviResponseData['return_code']
    if (returnCode !== undefined && returnCode === 0) {
      if (!_restore) {
        logEvent('ehon_in_app_purchase')
      }
      location.reload()
    } else if (returnCode == 302) {
      logEvent('ehon_in_app_purchase')
      location.href = ehonnaviResponseData['location']
    } else {
      const message =
        ehonnaviResponseData['return_message'] ||
        'アプリ内課金処理中にエラーが発生しました。サポートにお問い合わせください。'
      alertMessage(message)
      hideSpinner()
    }
  } catch {
    alertMessage('アプリ内課金処理中に予期しないエラーが発生しました。サポートにお問い合わせください。')
    hideSpinner()
  }
}

async function sendTransactionKey(key) {
  // TODO: ここでログイン済みかどうかチェックする https://app.bugsnag.com/sonicgarden/ehon-cp/errors/648981549d79330008adb34b?filters[event.since]=30d&filters[error.status]=open
  const res = await axios({
    url: '/premium/in_app_purchase_transactions.json',
    method: 'POST',
    headers: initializedHeaders(),
    data: {
      transaction_key: key,
    },
  })

  ios.postMessage('removeStoredTransactionKey', key)

  const { updated } = res.data
  if (updated) {
    location.reload()
  }
}

function checkPromotionOfferEligible(el) {
  showSpinner()
  const { product, offer } = el.dataset
  if (!ios.postMessage('checkPromotionOfferEligible', { product, offer })) {
    setTimeout(hideSpinner)
    alertMessage('App Storeからアプリをアップデートしてもう一度お試しください。')
  }
}

function handlePromotionOfferCheckResult(result) {
  if (result) {
    $('.js-premium-register').removeClass('disabled').html('購入する')
  } else {
    alertMessage(
      'このプロモーションを使う資格がありません。すでにこの商品を購入済みか、一度も購入していない可能性があります。',
    )
  }
  hideSpinner()
}

export function alertMessage(message) {
  ios.alert(message)
}
