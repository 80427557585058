import Rails from '@rails/ujs'
import { default as axios } from 'axios'
export { default as axios } from 'axios'

if (Rails.csrfToken()) {
  axios.defaults.headers.common['X-CSRF-Token'] = Rails.csrfToken()
}

export const initializedHeaders = () => {
  let headers = {}
  if (document.querySelector<HTMLMetaElement>('meta[name=contents-selection-id]')) {
    // NOTE: iOS 12 で `?.` が使用できないので、あえて一度変数に入れてから使用している
    const contentsSelectionIdElement = document.querySelector<HTMLMetaElement>('meta[name=contents-selection-id]')
    const contentIdElement = document.querySelector<HTMLMetaElement>('meta[name=content-id]')

    headers = {
      'X-CONTENTSELECTION-ID': contentsSelectionIdElement ? contentsSelectionIdElement.content : undefined,
      'X-CONTENT-ID': contentIdElement ? contentIdElement.content : undefined,
    }
  }
  return headers
}
