import $ from 'jquery'

function initPremiumServiceGuideTable() {
  $('.js-premium-service-guide-table .premium-service-guide-table-summary th').on('click', (ev) => {
    const $summary = $(ev.currentTarget)
    const $detail = $(`tr.premium-service-guide-table-detail[data-name=${$summary.attr('data-detail')}] td`)
    if ($summary.hasClass('is-open')) {
      $summary.removeClass('is-open')
      $detail.fadeOut()
    } else {
      $summary.addClass('is-open')
      $detail.fadeIn()
    }
  })
}

$(() => {
  initPremiumServiceGuideTable()
})
