import $ from 'jquery'
import { axios, initializedHeaders } from 'lib/axios'
import { triggerPageViewEvent } from 'lib/ga'

const findModal = () => $('#js-content-modal')

const showContentModal = async (el: HTMLAnchorElement) => {
  const $modal = findModal()
  const pageTitle = el.dataset.pageTitle
  const modalId = el.dataset.modalId
  const url = el.href

  if (!modalId) throw '"data-modal-id" is required.'

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  $modal.modal('show')
  if (pageTitle) {
    document.title = pageTitle
  }
  triggerPageViewEvent(pageTitle)

  const response = await axios({
    method: 'GET',
    url,
    headers: {
      ...initializedHeaders(),
      'X-Requested-With': 'XMLHttpRequest',
    },
  })
  const html = response.data
  $modal.find('#js-content-modal-spinner').hide()
  $modal.find('#js-content-modal-body').show().html(html)
  $modal.trigger('load')
}

const hideContentModal = (originalPageTitle: string) => {
  const $modal = findModal()
  $modal.find('#js-content-modal-spinner').show()
  $modal.find('#js-content-modal-body').html('').hide()

  document.title = originalPageTitle
}

const initContentModal = () => {
  const originalPageTitle = document.title

  $(document).on('click', '.js-content-modal', (el) => {
    el.preventDefault()
    showContentModal(el.currentTarget)
  })

  $('#js-content-modal').on('hidden.bs.modal', () => {
    hideContentModal(originalPageTitle)
  })
}

$(() => {
  initContentModal()
})
