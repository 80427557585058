import { onConnect } from '@sonicgarden/onconnect'
import { axios, initializedHeaders } from 'lib/axios'

const startObserve = (observer: IntersectionObserver, container: HTMLElement) => {
  const target = container.querySelector('.infinite-scroll-request.is-loading')
  if (target) {
    observer.observe(target)
  }
}

onConnect('.js-infinite-scroll', (el) => {
  const observer = new IntersectionObserver(async (entries) => {
    const [entry] = entries
    if (!entry.isIntersecting) {
      return
    }

    const target = entry.target as HTMLElement
    observer.unobserve(target)

    const parent = target.parentElement
    const nextUrl = target.dataset.nextUrl
    if (!nextUrl || !parent) {
      return
    }

    const response = await axios({
      method: 'GET',
      url: nextUrl,
      headers: {
        ...initializedHeaders(),
        'X-Requested-With': 'XMLHttpRequest',
      },
    })

    const divContainer = document.createElement('div')
    divContainer.innerHTML = response.data
    const newItems = divContainer.querySelector('.js-infinite-scroll')
    if (!newItems) {
      return
    }
    parent.outerHTML = newItems.innerHTML

    startObserve(observer, el)
  })
  startObserve(observer, el)
})
