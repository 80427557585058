import { onConnect } from '@sonicgarden/onconnect'
import { isAndroidApp, isIOSApp } from '../lib/platform'
import * as android from '../lib/bridge_android'
import * as ios from '../lib/bridge_ios'

onConnect('.js-native-alert', (el) => {
  const message = el.dataset.message
  if (isAndroidApp()) {
    android.alert(message)
  } else if (isIOSApp()) {
    ios.alert(message)
  } else {
    alert(message)
  }
})
