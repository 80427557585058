import Rails from '@rails/ujs'
import $ from 'jquery'
import './styles/main.sass'
import './sugotoku_emulator'
import '../lib/dkids_return'
import * as GA from '../lib/ga'
import '../lib/track'

import 'bootstrap'
import './initializers/mobile_app'
import './acti_book'
import './premium_home'
import './play_history'
import './infinite_scroll'
import './ehon_video'
import './ehon_acti_book'
import './screen_capture'
import './in_app_purchase'
import './sugotoku_sessions'
import './parental_gate'
import './premium_service_guide'
import 'lib/send_text'
import './dpoint_mission'
import 'lib/movie'

import './content_modal'
import '../lib/swiper'
import '../lib/contents_carousel'
import '../lib/dpoint_mission'
import '../lib/lazy_load'
import '../lib/update_element'
import '../lib/on_load_modal'
import './back_link'
import './native_alert'

global.$ = global.jQuery = $
Rails.start()
GA.start()
