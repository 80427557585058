import { onConnect } from '@sonicgarden/onconnect'
import { Modal } from 'bootstrap'

onConnect('.js-dpoint-mission-modal', (el) => {
  const modal = new Modal(el, { backdrop: 'static' })
  modal.show()

  const closeButton = el.querySelector<HTMLButtonElement>('.js-dpoint-mission-modal-close-button')
  if (closeButton) {
    closeButton.addEventListener('click', () => {
      modal.hide()
    })
  }
})
