const controlPageTopButton = () => {
  const $elem = $('.js-scroll-to-top')
  $(window).scroll(function () {
    $(this).scrollTop() > 300 ? $elem.fadeIn() : $elem.fadeOut()
  })
}

$(document).on('click', '.js-scroll-to-top', () => {
  $('body, html').animate({ scrollTop: 0 }, 500)
})

$(() => {
  controlPageTopButton()
})
