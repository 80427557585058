import { axios, initializedHeaders } from '../lib/axios'
import { modalBodyViewerClose } from './ehon_acti_book'

$(document).on('click', '.js-alert-message-close', (e) => {
  $('.ehon-acti-book-alert-message').removeClass('is-captured')
})

window.MOBILE_APP.count_screen_capture = async (type) => {
  const $iframe = $('.modal-body-viewer-content, .gm-book-viewer-container')
  if (type === 'take' && $iframe.length === 0) {
    console.log('絵本ファイルを開いていません')
    return false
  }

  const $elem = $('.js-ehon-acti-book-count-screen-capture')
  const $screenCaptureHistoriesUrl = $elem.data('screen-capture-histories-url')
  let res = await axios({
    url: $screenCaptureHistoriesUrl,
    method: 'POST',
    headers: initializedHeaders(),
  })
  if (!res.data.success) {
    console.log('スクリーンショットの回数更新に失敗しました')
  }
  if (res.data.skipped) {
    return false
  }

  // TODO: POST のレスポンスに諸々のデータを含めるようにしたのでそちらを使うように修正すると1リクエスト減らせる
  res = await axios({
    url: $screenCaptureHistoriesUrl,
    method: 'GET',
    headers: initializedHeaders(),
  })
  const userOverScreenCaptureHistorySignOutThreshold = res.data.user_over_screen_capture_history_sign_out_threshold
  modalBodyViewerClose()
  if (userOverScreenCaptureHistorySignOutThreshold) {
    $('#js-ehon-app-sign-out-form')[0].submit()
  } else {
    const $elem = $('.ehon-acti-book-alert-message')
    $elem.addClass('is-captured')
    $('.ehon-acti-book-alert-message-no-screen-capture').show()
  }
  return false
}
