import Rails from '@rails/ujs'
import $ from 'jquery'

$(() => {
  const $sugotokuEmulator = $('#sugotoku-emulator')
  if ($sugotokuEmulator.length > 0) {
    console.log($sugotokuEmulator.data('url'))
    Rails.ajax({
      url: $sugotokuEmulator.data('url'),
      type: 'POST',
      success(response) {
        console.log(response)
        $('html').html($('html', response).html())
      },
    })
  }
})
